import { OrganizationFragment } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import OrganizationBrandingForm from "@earnnest-e2-frontend/platform-views/src/mantine/OrganizationBrandingForm"
import OrganizationProfileForm from "@earnnest-e2-frontend/platform-views/src/mantine/OrganizationProfileForm"
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { useCallback, useState } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"
import tinycolor from "tinycolor2"

export default function BusinessProfileScreen({ setBackdropProps }) {
  const [createdOrg, setCreatedOrg] = useState<OrganizationFragment>(null)

  const onBrandingValuesChange = useCallback(
    (values) => {
      if (createdOrg) {
        const backdropColor = values.backgroundColor || null
        const logoToUse = tinycolor(backdropColor).isDark()
          ? values.logoDark
          : values.logoLight
        const backdrop = logoToUse ? (
          <Flex
            direction="column"
            align="center"
            sx={(theme) => ({
              borderBottomWidth: 2,
              borderColor: theme.fn.themeColor("dark.2"),
            })}>
            <Image src={logoToUse} width={300} />
            <Text mt={4}>
              {createdOrg?.name || "This organization"} uses Earnnest for secure
              digital real estate payments.
            </Text>
          </Flex>
        ) : null
        setBackdropProps({ backdrop, backdropColor })
      }
    },
    [createdOrg, setBackdropProps],
  )

  // Doesn't hold escrow, redirect
  if (createdOrg && createdOrg.slug && !createdOrg.holdsEscrow) {
    window.location.href = `${process.env.REACT_APP_DASHBOARD_DOMAIN}/${createdOrg.slug}`
  }

  // Sign up escrow landing page
  if (createdOrg && createdOrg.slug && createdOrg.holdsEscrow) {
    return (
      <Stack px={124}>
        <Space h="xl" />
        <Center sx={(theme) => ({ color: theme.fn.themeColor("green") })}>
          <RiCheckboxCircleFill size={128} />
        </Center>
        <Title align="center" size="h2" order={2}>
          Welcome, {createdOrg.name}
        </Title>
        <Text align="center">
          Thanks for joining the future of how money moves in real estate. Next,
          let's register your escrow accounts so you can receive payments
          through Earnnest.
        </Text>
        <PanelFooter>
          <Button
            variant="subtle"
            size="lg"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_DASHBOARD_DOMAIN}/${createdOrg.slug}`
            }}>
            Skip for now
          </Button>
          <Space h="xs" />
          <Button
            size="lg"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_DASHBOARD_DOMAIN}/${createdOrg.slug}/escrow-accounts/register`
            }}>
            Register your first account
          </Button>
        </PanelFooter>
      </Stack>
    )
  }

  // Branding step
  if (createdOrg) {
    return (
      <Box>
        <Space h="xl" />
        <Title size="h2" order={2}>
          Set up your brand in Earnnest
        </Title>
        <Space h="xs" />
        <OrganizationBrandingForm
          organization={createdOrg}
          submitLabel="Continue"
          onChange={onBrandingValuesChange}
          onSubmitSuccess={(updatedOrg) => {
            setCreatedOrg(updatedOrg)
          }}
        />
      </Box>
    )
  }

  // Profile step
  return (
    <Box>
      <Space h="xl" />
      <Title size="h2" order={2}>
        Enroll your business
      </Title>
      <Space h="xs" />
      <Text size="lg">
        Tell us about your business. We'll use this information during our
        verification process.
      </Text>
      <Space h="xl" />
      <OrganizationProfileForm
        onSubmitSuccess={(organization) => {
          setCreatedOrg(organization)
        }}
      />
    </Box>
  )
}
